import { lazy } from 'react';
import config from 'config';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// datasets
const Datasets = Loadable(lazy(() => import('views/datasets')));
const SearchPage = Loadable(lazy(() => import('views/datasets/SearchPage')));

// chat
const Chat = Loadable(lazy(() => import('views/chat')));
const CreateChat = Loadable(lazy(() => import('views/chat/CreateChat')));

//todos routing
const Todos = Loadable(lazy(() => import('views/todos')));

// users
const Profile = Loadable(lazy(() => import('views/users/Profile')));

// projects
const ProjectList = Loadable(lazy(() => import('views/projects/ProjectList')));

const Test = Loadable(lazy(() => import('views/test')));



// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Datasets />
        },
        {
            path: '/datasets',
            element: <Datasets />
        },
        {
            path: '/create',
            element: <CreateChat />
        },
        {
            path: '/search',
            element: <SearchPage />
        },
        {
            path: '/chat',
            element: <Chat />
        },
        {
            path: '/projects',
            element: <ProjectList />
        },
        {
            path: '/todos',
            element: <Todos />
        },
        {
            path: '/user/profile',
            element: <Profile />
        },
        {
            path: '/test',
            element: <Test />
        }
    ]
};

export default MainRoutes;
