import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase, Grid } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
import { setMenu } from 'slices/menu';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.menu.opened);
    const dispatch = useDispatch();

    return (
        <Grid container justifyContent="center" alignItems="center">
            <ButtonBase disableRipple onClick={() => dispatch(setMenu({opened: true}))} component={Link} to={config.defaultPath} sx={{ mb: 1 }}>
                <Logo />
            </ButtonBase>
        </Grid>
    )
}


export default LogoSection;
