import {useSelector} from 'react-redux';

import {CssBaseline, StyledEngineProvider} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import {SnackbarProvider} from 'notistack';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline/>
        <NavigationScroll>
          <SnackbarProvider maxSnack={5} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
            <Routes/>
          </SnackbarProvider>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
