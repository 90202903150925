import PropTypes from 'prop-types';

// material-ui
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PreviewIcon from '@mui/icons-material/Preview';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider, Drawer, Grid,
  IconButton,
  Link,
  ListItemIcon, ListItemText,
  Menu, MenuItem,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import moment from 'moment-timezone';

// third-party
import { BrowserView, MobileView } from 'react-device-detect';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useGetProjectsQuery } from 'services/projects';
import { logout } from 'slices/auth';
import { drawerWidth } from 'store/constant';
import LogoSection from '../LogoSection';
import MenuList from './MenuList';
import NewChat from './NewChat';

import { Stack } from '@mui/system';
import config from 'config';
import 'moment-timezone';
import Moment from 'react-moment';
import truncateString from 'utils/truncateString';
import { stringToHslColor } from '../../../utils/stringToHslColor';
import { CurrentUserContext } from '../../../context/CurrentUserContext';
import {useSearchParams} from "react-router-dom";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';


const ProfileSection = () => {
  const user = useContext(CurrentUserContext);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    navigate(`${config.prefix}/login`);
  };

  const handleUserProfile = () => {
    navigate(`${config.prefix}/user/profile`);
  }

  const openDemoVideo = () => {
    window.open('https://tcpaplus.drbioright.org/demo', '_blank');
  }

  if (isLoggedIn && user) {
    return (
      user.roles.includes('invited_user') ? (
        <Box padding={1} mt={1}>
          <Button
            variant='contained'
            startIcon={<PlayCircleIcon />}
            sx={{ width: '100%'}}
            onClick={openDemoVideo}
          >
            Watch demo video
          </Button>
        </Box>
        ) :
        <>
          <Box padding={1} mt={1}>
            <Button
              id="user-profile"
              variant="outlined"
              color="primary"
              // startIcon={<Gravatar email="Dr.BioRight@example.com" size={30} />}
              // startIcon={<img src={favicon} width={20} />}
              startIcon={<AccountCircleIcon />}
              endIcon={<MoreVertIcon />}
              sx={{ textTransform: 'lowercase', width: '100%' }}
              size="small"
              onClick={handleClick}
              aria-controls={open ? 'user-profile-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              {/* {username || "Dr.BioRight@example.com"} */}
              {truncateString(user?.email, 25)}
            </Button>
            <Menu
              id="user-profile-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              sx={{ marginLeft: '-8px' }}
              PaperProps={{ sx: { width: '245px' } }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
            >
              {
                user.roles.includes('admin') &&
                <MenuItem onClick={() => navigate(`${config.prefix}/admin`)}>
                  <ListItemIcon>
                    <AdminPanelSettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Admin Platform</ListItemText>
                </MenuItem>
              }
              {user.roles.includes('admin') && <Divider />}
              <MenuItem onClick={handleUserProfile}>
                <ListItemIcon>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>User profile</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </>
    );
  } else {
    return (
      config.appName !== 'tcpaplus' && <>
        <Box paddingY={1}>
          <Typography align="center" padding={2} fontWeight={'bold'}>
            <Link href={`${config.prefix}/login`} underline="none" color="secondray">
              Login
            </Link>{' '}
            /{' '}
            <Link href={`${config.prefix}/invite_signup`} underline="none" color="secondray">
              Sign Up
            </Link>
          </Typography>
        </Box>
      </>
    );
  }
};

const ProjectList = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { data: projects, isLoading } = useGetProjectsQuery({ short: false, last_n: 15 });
  const navigate = useNavigate();
  const [currentProject, setCurrentProject] = useState(null);
  const currentProjectId = useSelector((state) => state.project.currentProject);

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event, project) => {
    setAnchorEl(event.currentTarget);
    setCurrentProject(project);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handlePreviewClicked = () => {
    setTimeout(() => {
      navigate(`${config.prefix}/chat?project_id=${currentProject._id}`);
    }, 500)
    handlePopoverClose();
  }

  const handleDeleteClicked = () => {
    setTimeout(() => {
      navigate(`${config.prefix}/chat?project_id=${currentProject._id}`);
    }, 500)
    handlePopoverClose();
  }

  const handleEditClicked = () => {
    setTimeout(() => {
      navigate(`${config.prefix}/chat?project_id=${currentProject._id}`);
    }, 500)
    handlePopoverClose();
  }

  const handleProjectClicked = (e, project) => {
    setTimeout(() => {
      navigate(`${config.prefix}/chat?project_id=${project._id}`);
    }, 500)
  }

  return (<Box>
    {projects?.map((project, index) => (
      <Box key={`projects-lastn-${index}`}>
        <Box
          sx={{
            border: '1px solid',
            borderRadius: '4px',
            padding: '3px',
            position: 'relative',
            cursor: 'pointer',
            marginBottom: '5px',
            borderLeft:  project._id === searchParams.get('project_id') ? "4px solid #1976d2" : '',
            bgcolor: project._id === searchParams.get('project_id') ? '#e3f2fd' : '',
            borderColor: project._id === searchParams.get('project_id') ? '#1976d2' : '',
          }}
          // onMouseEnter={handlePopoverOpen}
          // onMouseLeave={handlePopoverClose}
          onClick={(e) => handleProjectClicked(e, project)}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item mx={1}>
              <Avatar sx={{ bgcolor: stringToHslColor(project.dataset.shortname, 45, 55), color: 'white', width: 26, height: 26, fontSize: '16px' }} aria-label="recipe" >
                {project.dataset.shortname.charAt(0).toUpperCase()}
              </Avatar>
            </Grid>
            <Grid item>
              <Typography title={project.name} sx={{ fontSize: 12 }}>{project.dataset.shortname.length > 22 ? project.dataset.shortname.slice(0, 22) + "..." : project.dataset.shortname}</Typography>
            </Grid>
          </Grid>
          <Box sx={{ position: 'absolute', right: '5px', bottom: -5, height: '20px', fontSize: 10 }}>
            <Moment fromNow>{moment.utc(project.updated_at)}</Moment>
          </Box>
        </Box>
        <Popover
          sx={{
            // pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Card sx={{ width: '350px' }}>
            <CardContent>
              <Grid
                container
                component='dl'
                spacing={2}>
                <Grid item xs={3}>
                  <Typography component='dt' variant='h6'>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography component='dd' variant='body2'>
                    {project.title}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography component='dt' variant='h6'>
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography component='dd' variant='body2'>
                    {project.description}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography component='dt' variant='h6'>
                    Dataset
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography component='dd' variant='body2'>
                    {project.dataset.name}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography component='dt' variant='h6'>
                    Labels
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography component='dd' variant='body2'>
                    <Stack direction="row" spacing={1}>
                      <Chip label="default" color="secondary" variant="outlined" size="small" />
                      <Chip label="important" color="error" variant="outlined" size="small" />
                    </Stack>
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography component='dt' variant='h6'>
                    Created at
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography component='dd' variant='body2'>
                    <Moment fromNow>{project.created_at}</Moment>
                  </Typography>
                </Grid>


                <Grid item xs={3}>
                  <Typography component='dt' variant='h6'>
                    Updated at
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography component='dd' variant='body2'>
                    <Moment fromNow>{project.updated_at}</Moment>
                  </Typography>
                </Grid>

              </Grid>
            </CardContent>
            <CardActions sx={{ padding: '8px', display: 'flex', justifyContent: 'flex-end', pt: '0' }}>
              <Tooltip title="Delete">
                <IconButton mx={1} size="small" onClick={handleDeleteClicked}><DeleteIcon /></IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton mx={1} size="small" onClick={handleEditClicked}><EditIcon /></IconButton>
              </Tooltip>
              <Tooltip title="View">
                <IconButton mx={1} size="small" onClick={handlePreviewClicked}><PreviewIcon /></IconButton>
              </Tooltip>
            </CardActions>
          </Card>
        </Popover>
      </Box>


    ))
    }

    {projects?.length >= 1 ? <Button startIcon={<HistoryIcon />} variant='outlined' color='primary' sx={{ width: '100%', marginTop: '20px' }} onClick={() => navigate('/projects')}>All projects</Button> : null}

  </Box >)
}

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const drawer = (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? 'calc(100vh - 53px)' : 'calc(100vh - 88px)',
            paddingLeft: '16px',
            paddingRight: '16px'
          }}
        >
          <LogoSection />
          <Divider />
          <NewChat />
          <ProjectList />
        </PerfectScrollbar>
        <Divider />
        <ProfileSection />
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
        </Box>
      </MobileView>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant='persistent'
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            pt: 2
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
