const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: process.env.REACT_APP_BASENAME || '',
    baseUrl: process.env.REACT_APP_BASE_URL,
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
    websocketBaseUrl: process.env.REACT_APP_WEBSOCKET_BASE_URL,
    aiApiBaseUrl: process.env.REACT_APP_AI_API_BASE_URL,
    shinyBaseUrl: process.env.REACT_APP_SHINY_SERVER_BASE_URL,
    defaultPath: '/',
    prefix: process.env.REACT_APP_PREFIX ? `/${process.env.REACT_APP_PREFIX}` : '',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    appName: process.env.REACT_APP_NAME || 'drbioright',
    appVersion: process.env.REACT_APP_VERSION || '1.0.0',
};

export default config;
